<template>
  <div>
    <v-card v-if="!crudEnabled" key="invitesList">
      <v-toolbar>
        <v-card-title>Invites</v-card-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="$can('create', 'Superadmin') || $can('create', 'users_invites')"
          :color="Pallette.actionButtons.newItem"
          fab
          dark
          absolute
          bottom
          right
          :small="!$vuetify.breakpoint.smAndUp"
          @click.stop="createInvite()"
          data-testid="btn-create-invite"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <DataTableExtended
          ref="refTableInvites"
          :headers="headersComputed"
          :items="invitesList"
          sortable
          class="elevation-0 border"
          :loading="showTableLoader"
          :no-data-text="showTableLoader ? 'Loading…' : 'No data available'"
          v-on:init-table-data="fetchInvitesList"
          :server-items-length="invitesCount"
          :customSearch="filterSearch"
          @update-search="
            (newS) => {
              this.filterSearch = newS
            }
          "
          data-testid="table-invites"
        >
          <template v-slot:top>
            <v-row class="pa-3">
              <v-col cols="12" md="3">
                <v-text-field
                  outlined
                  v-model="filterSearch"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  clearable
                  data-testid="filter-search"
                />
              </v-col>
              <v-col cols="12" md="3" :offset-md="6">
                <CompanyFilter
                  v-if="$can('create', 'Superadmin')"
                  :hideDetails="true"
                  v-on:update:selected="onCompanyFilterChange($event)"
                  data-testid="filter-company"
                />
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.group.name`]="{ item }">
            <div data-testid="company-name">
              {{ item.group ? item.group.name : "" }}
            </div>
          </template>
          <template v-slot:[`item.role.name`]="{ item }">
            <div data-testid="role-name">
              {{ item.role.name }}
            </div>
          </template>
          <template v-slot:[`item.email`]="{ item }">
            <div data-testid="email">
              {{ item.email }}
            </div>
          </template>
          <template v-slot:[`item.date_create`]="{ item }">
            <div data-testid="date-created">
              {{ item.date_create | unixDateTimeFormat }}
            </div>
          </template>
          <template v-slot:[`item.date_update`]="{ item }">
            <div data-testid="date-updated">
              {{ item.date_update | unixDateTimeFormat }}
            </div>
          </template>
          <template
            v-slot:[`item.delete`]="{ item }"
            v-if="$vuetify.breakpoint.smAndUp"
          >
            <div class="text-center d-flex align-center justify-space-around">
              <v-tooltip
                top
                v-if="
                  isSuperUser || $can('create', 'users_invites_resend_email')
                "
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    @click.stop="resendEmail(item)"
                    :color="Pallette.actionButtons.edit"
                    dark
                    fab
                    x-small
                    v-on="on"
                    data-testid="btn-reset-email"
                  >
                    <v-icon>mdi-send</v-icon>
                  </v-btn>
                </template>
                <span>Resend email</span>
              </v-tooltip>

              <v-tooltip top v-if="$can('delete', 'users_invites')">
                <template v-slot:activator="{ on }">
                  <v-btn
                    @click.stop="deleteInvitation(item)"
                    :color="Pallette.actionButtons.delete"
                    dark
                    fab
                    x-small
                    v-on="on"
                    data-testid="btn-delete-invitation"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Delete invitation</span>
              </v-tooltip>
            </div>
          </template>
          <template
            v-slot:expanded-item="{ headers, item }"
            v-if="
              !$vuetify.breakpoint.smAndUp &&
              ($can('delete', 'users_invites') || $can('delete', 'Superadmin'))
            "
          >
            <td :colspan="headers.length">
              <v-row align="center" justify="space-around">
                <VerticalButton
                  @click.native="resendEmail(item)"
                  v-if="
                    isSuperUser ||
                    $can('users_invites_resend_email', 'users_invites')
                  "
                  icon="mdi-send"
                  text="Resend email"
                  :color="Pallette.actionButtons.edit"
                  data-testid="btn-reset-email"
                />

                <VerticalButton
                  @click.native="deleteInvitation(item)"
                  v-if="$can('create', 'users_invites_resend_email')"
                  icon="mdi-delete"
                  text="Delete invitation"
                  :color="Pallette.actionButtons.delete"
                  data-testid="btn-delete-invitation"
                />
              </v-row>
            </td>
          </template>
        </DataTableExtended>
      </v-card-text>
    </v-card>

    <v-form
      ref="form"
      data-vv-scope="form-invitation"
      v-if="crudEnabled"
      key="createInviteForm"
      data-testid="form-invitation"
    >
      <v-card>
        <v-toolbar>
          <v-card-title>Create invite</v-card-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="12">
              <v-alert
                v-model="createInvitesError"
                border="left"
                close-text="Close Alert"
                color="deep-purple accent-4"
                dark
                dismissible
                data-testid="create-invitation-errors"
              >
                This e-mail " {{ activeItem.email }} " is already in use
              </v-alert>
            </v-col>
            <v-col cols="12" md="12">
              <v-row>
                <v-col cols="6" md="6">
                  <v-text-field
                    key="inviteEmail"
                    v-model.trim="activeItem.email"
                    data-vv-name="email"
                    v-validate="'required|emailWithSpaces'"
                    label="E-mail"
                    outlined
                    :error-messages="errors.collect('form-invitation.email')"
                    data-testid="email"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="12">
              <v-row>
                <v-col cols="6" md="6">
                  <v-text-field
                    v-if="$can('create', 'Superadmin')"
                    v-model="activeItem.group_key"
                    :error-messages="errors.collect('form-invitation.company')"
                    data-vv-name="company"
                    data-vv-validate-on="change"
                    v-validate="'required'"
                    class="hide-element"
                    data-testid="company-text"
                  />
                  <SelectCompaniesAsync
                    label="Company"
                    v-if="$can('create', 'Superadmin')"
                    v-model="activeItem.group_key"
                    :error-messages="errors.collect('form-invitation.company')"
                    data-vv-name="company"
                    data-vv-validate-on="change"
                    v-validate="'required'"
                    outlined
                    item-value="_key"
                    item-text="name"
                    data-testid="company-select"
                  />
                  <v-text-field
                    v-else
                    outlined
                    type="text"
                    label="Company"
                    readonly
                    v-model="companyNameForCompanyAdmin"
                    disabled
                    data-testid="company-text"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" md="6">
              <v-select
                key="inviteRole"
                outlined
                v-model="activeItem.role_key"
                :items="filteredRoles"
                item-text="name"
                item-value="_key"
                :menu-props="{ maxHeight: '400', light: false }"
                label="Role"
                :error-messages="errors.collect('form-invitation.role')"
                data-vv-name="role"
                v-validate="'required'"
                data-vv-validate-on="change"
                data-testid="role"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="error"
            text
            @click.stop="close()"
            data-testid="btn-close-create-invitation"
          >
            Close
          </v-btn>
          <v-btn
            color="success"
            text
            @click.stop="save()"
            data-testid="btn-save-invite"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <Confirm ref="confirmDialog"></Confirm>
  </div>
</template>

<script>
import { cloneDeep } from "lodash"
import { mapGetters } from "vuex"
import CompanyFilter from "@/components/filters/CompanyFilter"
import VerticalButton from "@/components/buttons/VerticalButton"
import Confirm from "@/components/Confirm"
import SelectCompaniesAsync from "@/components/selects/SelectCompaniesAsync"
import DataTableExtended from "@/components/table/DataTableExtended"
import RolesMixin from "@/mixins/role-mixin.js"

export default {
  components: {
    CompanyFilter,
    VerticalButton,
    Confirm,
    SelectCompaniesAsync,
    DataTableExtended,
  },

  mixins: [RolesMixin],

  data() {
    return {
      headers: [
        {
          text: "Company name",
          align: "left",
          value: "group.name",
          sortable: false,
        },
        {
          text: "Role name",
          align: "left",
          value: "role.name",
          sortable: false,
        },
        {
          text: "Email",
          align: "left",
          value: "email",
          sortable: true,
        },
        {
          text: "Created",
          align: "center",
          value: "date_create",
          sortable: true,
        },
        {
          text: "Updated",
          align: "center",
          value: "date_update",
          sortable: true,
        },
        {
          text: "",
          value: "delete",
          sortable: false,
          width: 110,
        },
      ],
      activeItem: {
        email: "",
        group_key: "",
        role_key: "",
      },
      defaultItem: {
        email: "",
        group_key: "",
        role_key: "",
      },
      crudEnabled: false,
      createInvitesError: false,
      companyNameForCompanyAdmin: "",
      filterSearch: "",
      filterByCompany: null,
    }
  },

  mounted: function () {
    if (!this.$can("read", "users_invites")) {
      if (!this.$can("read", "Superadmin"))
        this.$router.push({ name: "home" }).catch((err) => {})
    }
  },

  watch: {
    filterSearch() {
      this.fetchInvitesList()
    },
    filterByCompany() {
      this.fetchInvitesList()
    },
    crudEnabled(val) {
      let self = this
      if (val) {
        if (!self.$can("read", "Superadmin")) {
          let params = {
            p: 1,
            pp: 500,
          }
          self.$store.dispatch("getUserGroups", params).then(() => {
            self.activeItem.group_key = self.getUserGroupsList[0]._key
            self.companyNameForCompanyAdmin = self.getUserGroupsList[0].name
          })
        }
        self.fetchRolesList().then(() => {
          if (self.filteredRoles.length > 0) {
            self.activeItem.role_key = self.filteredRoles[0]._key
          }
        })
      }
    },
  },

  methods: {
    fetchRolesList() {
      let self = this
      let fetchData = {
        params: {
          p: 1,
          pp: 500,
          keep: "_key,name,permissions",
        },
      }
      return self.$store.dispatch("getRolesList", fetchData)
    },
    onCompanyFilterChange(event) {
      this.filterByCompany = event
    },
    fetchInvitesList: function () {
      let self = this
      if (
        this.$can("read", "Superadmin") ||
        this.$can("read", "users_invites")
      ) {
        let getParams = self.$refs.refTableInvites.getTableServerParams()
        if (self.filterByCompany) getParams.group_key = self.filterByCompany
        if (self.filterSearch !== "") getParams.search = self.filterSearch
        let fetchData = {
          params: getParams,
        }
        self.$store.dispatch("getInvitesList", fetchData)
      }
    },
    createInvite() {
      this.crudEnabled = true
      this.activeItem = cloneDeep(this.defaultItem)
    },
    save() {
      let self = this
      let email = this.activeItem.email
      let formData = {
        data: {
          email: email.trim(),
          group_key: this.activeItem.group_key,
          role_key: this.activeItem.role_key,
        },
      }
      self.$validator.errors.clear("form-invitation")
      self.$validator.validateAll("form-invitation").then((validated) => {
        if (validated) {
          self.$store
            .dispatch("createInvitation", formData)
            .then((response) => {
              self.close()
              setTimeout(() => {
                self.fetchInvitesList()
              }, 400)
            })
            .catch((error) => {
              self.createInvitesError = true
              setTimeout(() => {
                self.createInvitesError = false
              }, 1000)
              if (error) return false
            })
        }
      })
    },
    deleteInvitation(item) {
      let self = this
      self.$refs.confirmDialog
        .open("Delete", "Are you sure you want to delete this invitation?", {
          color: "red darken-4",
        })
        .then((confirm) => {
          if (confirm) {
            self.$store
              .dispatch("deleteInvitation", item._key)
              .then(() => {
                self.fetchInvitesList()
              })
              .catch((error) => {
                console.log("error on deleteInvitation", error)
              })
          }
        })
    },
    close() {
      this.activeItem = cloneDeep(this.defaultItem)
      this.crudEnabled = false
    },
    resendEmail(item) {
      let self = this
      self.$store
        .dispatch("resendEmailInvitation", item._key)
        .then((response) => {
          let params = {
            text: `Invitation email for user ${item.email} was resend with success!`,
            show: true,
          }
          self.$root.$emit("snackbar", params)
          self.fetchInvitesList()
        })
        .catch((error) => {
          console.log("error on deleteInvitation", error)
        })
    },
  },

  computed: {
    ...mapGetters({
      showTableLoader: "getShowTableLoader",
      Pallette: "get_Pallette",
      invitesList: "invitesList",
      invitesCount: "invitesCount",
      isSuperUser: "isSuperUser",
      rolesList: "rolesList",
      getUserGroupsList: "getUserGroupsList",
    }),
    headersComputed() {
      if (this.$vuetify.breakpoint.smAndUp) {
        return this.headers
      } else {
        let sorted = this.headers.map((h) => {
          if (h.value !== "delete") return h
        })
        let headers = sorted.filter(function (el) {
          return el != undefined
        })
        return headers
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.selectPagination {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  border-top: 1px solid rgba(255, 255, 255, 0.3);

  button,
  span {
    flex: 0 0 auto;
  }
}
</style>
