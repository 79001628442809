import _ from "lodash"
import { mapGetters } from "vuex"

export default {
  data() {
    return {
      allPermissions: [],
      flatPermissions: [],
    }
  },

  created() {
    this.getRolesList()
  },

  methods: {
    getRolesList(fetchData = {}) {
      let self = this
      if (self.$can("read", "roles") || self.$can("read", "Superadmin")) {
        self.$store.dispatch("getRolesList", fetchData)
      }
    },
    // TODO Refactor this to computed store data
    flatternPermissionsRebuild() {
      return new Promise((resolve) => {
        var flat_ws = _.chain(this.allPermissions)
          .map("permissions")
          .flatten()
          .map("ws")
          .flatten()
          .map("handlers")
          .flatten()
          .map((item) => item.permission)
          .value()

        var flat_rest = _.chain(this.allPermissions)
          .map("permissions")
          .flatten()
          .map("rest")
          .flatten()
          .map("handlers")
          .flatten()
          .map("methods")
          .flatten()
          .map((item) => item.permission)
          .value()

        var flat_union = _.union(flat_ws, flat_rest)
        let form_data = {}
        flat_union.forEach((item) => {
          form_data[item] = false
        })
        this.flatPermissions = form_data
        resolve()
      })
    },
    getAllPermissions() {
      let self = this
      return new Promise((resolve) => {
        self.$store
          .dispatch("getAllPermissions")
          .then((response) => {
            let data = response.data.data.services
            self.allPermissions = data
            self.flatternPermissionsRebuild().then(() => {
              resolve()
            })
          })
          .catch(({ response }) => {
            console.log("error on getRolesList", response)
            resolve()
          })
      })
    },
  },

  computed: {
    ...mapGetters({
      rolesList: "rolesList",
      rolesCount: "rolesCount",
      isSuperUser: "isSuperUser",
    }),
    simpleRoles() {
      return this.rolesList.filter(
        (r) => !r.permissions.includes("__superuser__")
      )
    },
    // For not superuser - show only simple roles list
    filteredRoles() {
      return this.isSuperUser ? this.rolesList : this.simpleRoles
    },
  },
}
